var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "py-1"
  }, [_c('h4', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")]), _vm.isManager ? _c('b-button', {
    staticClass: "px-75 py-50",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.handleShowModalResell
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('resellTicket.create')) + " ")], 1)]) : _vm._e()], 1), _c('b-card-body', {
    staticClass: "pt-0 pb-50"
  }, [_c('div', {
    staticClass: "d-flex-center gap-2"
  }, [_c('b-row', {
    staticClass: "flex-1"
  }, [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "searchText"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.columns.paxName')) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "id": "searchText",
      "name": _vm.$t('resellTicket.columns.paxName'),
      "placeholder": _vm.$t('resellTicket.columns.paxName'),
      "debounce": "500"
    },
    model: {
      value: _vm.filter.searchText,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "searchText", $$v);
      },
      expression: "filter.searchText"
    }
  })], 1)], 1), _vm.isManager ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "searchPnr"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.columns.pnr')) + " ")];
      },
      proxy: true
    }], null, false, 1764845227)
  }, [_c('b-form-input', {
    attrs: {
      "id": "searchPnr",
      "name": _vm.$t('resellTicket.columns.pnr'),
      "placeholder": _vm.$t('resellTicket.columns.pnr'),
      "debounce": "500",
      "maxlength": "6",
      "minlength": "6"
    },
    on: {
      "input": function input(val) {
        _vm.filter.pnr = val.trim().toUpperCase();
      }
    },
    model: {
      value: _vm.filter.pnr,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "pnr", $$v);
      },
      expression: "filter.pnr"
    }
  })], 1)], 1) : _vm._e(), _vm.isRoleF1 ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "type",
      "label": _vm.$t('resellTicket.btn.report')
    }
  }, [_c('v-select', {
    attrs: {
      "id": "type",
      "placeholder": _vm.$t('resellTicket.reported'),
      "options": ['reported', 'unReported'],
      "clearable": false,
      "reduce": function reduce(val) {
        return val === 'reported';
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.' + data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.' + data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 3669595880),
    model: {
      value: _vm.filter.reported,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "reported", $$v);
      },
      expression: "filter.reported"
    }
  })], 1)], 1) : _vm._e(), _vm.isManager ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "type",
      "label": _vm.$t('resellTicket.columns.status')
    }
  }, [_c('v-select', {
    attrs: {
      "id": "type",
      "placeholder": _vm.$t('resellTicket.columns.status'),
      "options": _vm.status,
      "clearable": true
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.status.' + data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.status.' + data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 2884547368),
    model: {
      value: _vm.filter.status,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "status", $$v);
      },
      expression: "filter.status"
    }
  })], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "p-50 rounded-circle",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "revisionOutline",
      "size": "18"
    }
  })], 1)], 1)], 1), _vm.isManager ? _c('b-row', {
    staticClass: "flex flex-column px-1 py-0"
  }, [_c('span', {
    staticClass: "text-info h4"
  }, [_vm._v(_vm._s(_vm.$t('resellTicket.note.title')))]), _c('span', [_vm._v("- " + _vm._s(_vm.$t('resellTicket.note.note1')))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("- " + _vm._s(_vm.$t('resellTicket.note.note2')))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("- " + _vm._s(_vm.$t('resellTicket.note.note3')))])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }